import React from 'react';
import LocationPageTemplate from '../../components/Location/LocationPageTemplate';

function LahoreOffice() {
  return (
    <LocationPageTemplate
      analyticsPageviewTitle="Location/Pakistan/Lahore"
      city="Lahore"
      mapSrc="https://maps.google.com/maps?q=Tintash&t=&z=13&ie=UTF8&iwloc=&output=embed&zoom=mn"
      locationImgSrc={require('../../assets/images/location/lhr.png').default}
      addresses={[
        {
          title: 'Tintash Pvt Ltd',
          description: '725 Shadman, Lahore, Punjab 54000, Pakistan',
        },
        {
          title: 'Tinytech LLP',
          description: '3rd Floor Vogue Towers, MM Alam Road, Block CII, Gulberg III, Lahore',
        },
      ]}
    />
  );
}

export default LahoreOffice;
